import React from 'react';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Link, injectIntl } from "gatsby-plugin-react-intl"
import { message } from 'antd';
import {
  Form, Input, Tooltip, Icon, Select, Checkbox, Button, Alert
} from 'antd';
import Loader from './Loader.gif'
import { CAPTCHA_SITE_KEY, ACTIVATE_URL } from '../../data/data';

const { Option } = Select;

const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class ActivateFormDraft extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    showAlert: 'none',
    loading: false,
    hCaptchaToken: ''
  };

  handleSubmit = (values) => {
    if (!this.state.hCaptchaToken) {
      message.error('Please verify captcha');
      return;
    }
    if (!ACTIVATE_URL) {
      message.error('Not Support yet');
      return;
    }

    this.setState({ showAlert: 'none', loading: true });
    const language = navigator.language || navigator.userLanguage;

    axios.post(ACTIVATE_URL, {
      captchaToken: this.state.hCaptchaToken,
      language,
      ...values
    }).then((response) => {
      // console.log({ response });
      this.setState({ loading: false });
      response.data.success ?
        message.success('Please Check your email') : message.error(response.data.msg);
    }).catch((error) => {
      console.log({ error });
    });
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;


    return (
      <>
      { this.state.loading ?
        <div style={{ position: 'fixed', top: '0', left: '0',width: '100%', height: '100%', backgroundColor: '#F0F0F0', opacity: '0.7', zIndex: '1' }}>
          <div style={{ zIndex: '10', opacity: '0.85', margin: 'auto', display: 'block', position: "absolute", top: "50%", textAlign: 'center', left: "50%", transform: 'translate(-50%, -50%)' }}>
            <img src={Loader} style={{ width: '200px' }} alt='Loading...' />
            <h3 style={{ color: '#696969' }}>Processing...</h3>
          </div>
        </div> : null
      }
      <Form
        {...FORM_ITEM_LAYOUT}
        onFinish={this.handleSubmit}
      >
        <Form.Item
          label={<span>{intl.formatMessage({ id: "email" })}</span>}
          name='email' rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Please input your E-mail!' }
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "serial" })}
          name='serial'
          rules={[
            { required: true, message: 'Please input your Serial Number!' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "machineCode" })}
          name="machineCode"
          rules={[{ required: true, message: 'Please input your machine code!', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <HCaptcha
          sitekey={CAPTCHA_SITE_KEY}
          onVerify={(hCaptchaToken) => {
            this.setState({ hCaptchaToken });
          }}
          onExpire={() => {
            this.setState({ hCaptchaToken: '' });
          }}
        />
        <div style={{textAlign:'center'}}>
          <Button type="primary" htmlType="submit">
          {intl.formatMessage({ id: "submit" })}
          </Button>
        </div>
      </Form>
      </>
    );
  }
}

// const DemoForm = Form.create({ name: 'register' })(ActivateFormDraft);

export default injectIntl(ActivateFormDraft)
