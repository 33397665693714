import React from 'react'
import MediaQuery from 'react-responsive'
import useProgressiveImg from '../components/useProgressiveImg'
import { default as AntdLayout } from 'antd/lib/layout'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Header from '../components/Header/HeaderDoc'
import Helmet from 'react-helmet'
import { useIntl } from "gatsby-plugin-react-intl"
import useTitle from '../components/Title';
import activatecss from './activate.css'
import Container from '../components/Container/ContainerTailor'
import WrappedActivateForm from '../components/Form/ActivateForm'

const ActivatePage = () => {
  const intl = useIntl();
  const title = useTitle();
  return (
    <MediaQuery maxWidth={1000}>
    {matches => (
      <>
        <Helmet
          title={intl.formatMessage({ id: "title" })}
          meta={[
            { name: 'description for index', content: 'Sample for index' },
            { name: 'keywords for index', content: 'index' },
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <AntdLayout>
          <AntdLayout.Header
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              zIndex: 100
            }}
          >
            <Row>
              <Col style={{ width:'100%' }}>
                <Header
                  siteTitle={title}
                  sidebarDocked={!matches}
                />
              </Col>
            </Row>
          </AntdLayout.Header>
          {/* main demo form */}
          <Container>
            <div style={{ display:'flex', minHeight:'100%', marginTop:'2rem', width:'100vw',flexWrap:'wrap'}}>
              <div className='request-activate' >
                <div className="left-activate">
                <h1>
                  {intl.formatMessage({ id: "activate_title" }, { title: intl.formatMessage({ id: 'title' }) })}
                </h1>
                  <p>
                    {intl.formatMessage({ id: "activate_description" }, { title: intl.formatMessage({ id: 'title' }) })}
                  </p>
                </div>
              </div>
              <div className="request-activate">
                <div className='activate-form'>
                  <WrappedActivateForm int={intl.locale}/>
                </div>
              </div>
              <div className='get-machine-code-bolck' style={{ backgroundColor: 'rgb(248, 248, 248)' }}>
                <div className="footer-activate">
                  <div style={{ textAlign:"center" }}>
                    <div style={{ color: '#555555', padding: '25px'}}>
                      <div style={{fontSize: '14px', color: '#555555'}}>
                        <p style={{ wordBreak: 'break-word', textAlign: 'left', fontSize: '26px' }}>
                          {intl.formatMessage({ id: "get_machine_code_title" })}
                        </p>
                        <ul style={{ listStyleType: 'square' }}>
                          <li style={{
                            fontSize: '18px',
                            wordBreak: 'break-word',
                            textAlign: 'left'
                          }}>
                            {intl.formatMessage({ id: "get_machine_code_open_terminal" })}
                          </li>
                          <li style={{
                            fontSize: '18px',
                            wordBreak: 'break-word',
                            textAlign: 'left'
                          }}>
                            {intl.formatMessage({ id: "get_machine_code_type_terminal" })}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </AntdLayout>
          </>
        )}
      </MediaQuery>
  )
}

export default ActivatePage

